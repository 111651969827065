#header {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
}

#header .logo {
  animation-name: logointro;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: .6s;
}

#header p {
  animation-name: headertextintro;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1.6s;
}

@keyframes logointro {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes headertextintro {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#scroller {
  position: absolute;
  bottom: 6em;
  left: 50%;
  cursor: pointer;
  z-index: 2;
}

#scroller span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  box-sizing: border-box;
}

#scroller span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

#scroller span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: '';
  width: 46px;
  height: 46px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 0;
  animation: scrollindicator 2s infinite;
  box-sizing: border-box;
}

@keyframes scrollindicator {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#featured a img {
  height: 2.5em;
}

#testimonials {
  background-color: #f8fafc;
}

#testimonials a {
  display: inline-block;
  margin-bottom: 1.625em;
}

#testimonials a img {
  display: block;
  height: 75px;
}

@media screen and (min-width: 31.5rem) {
  #testimonials a img {
    height: 100px;
  }
}

@media screen and (min-width: 61.5rem) {
  #testimonials a img {
    height: 125px;
  }
}

#testimonials ul {
  position: relative;
  margin: 0;
  padding: 0;
  height: 6em;
  list-style: none;
}

@media screen and (min-width: 31.5rem) {
  #testimonials ul {
    height: 4em;
  }
}

#testimonials li {
  position: absolute;
  top: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  text-align: center;
  font-size: 1em;
  font-style: italic;
  animation: rotateWordsFirst 30s linear infinite 0s;
  transform: translateY(-50%);
}

#testimonials li:nth-child(2) {
  animation-delay: 5s;
}

#testimonials li:nth-child(3) {
  animation-delay: 10s;
}

#testimonials li:nth-child(4) {
  animation-delay: 15s;
}

#testimonials li:nth-child(5) {
  animation-delay: 20s;
}

#testimonials li:nth-child(6) {
  animation-delay: 25s;
}

@media screen and (min-width: 61.5rem) {
  #testimonials li {
    font-size: 1.5em;
  }
}

@keyframes rotateWordsFirst {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  4% {
    opacity: 1;
  }
  12% {
    opacity: 1;
  }
  18% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#about #about-text {
  flex-basis: auto;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

@media screen and (min-width: 61.5rem) {
  #about #about-text {
    flex-basis: auto;
    margin-left: 10.75rem;
    margin-right: 10.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

@media screen and (min-width: 81.5rem) {
  #about #about-text {
    flex-basis: auto;
    margin-left: 15.75rem;
    margin-right: 15.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

#about p {
  text-align: justify;
}

#services h2 {
  font-family: 'Cinzel', Arial;
  font-size: 1.75em;
  letter-spacing: -1px;
}

@media screen and (min-width: 81.5rem) {
  #services h2 {
    font-size: 2.25em;
  }
}

#services h2 span {
  display: block;
}

#services ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

#services li {
  margin: 1.625em 0;
  flex-basis: auto;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

@media screen and (min-width: 41.5rem) {
  #services li {
    flex-basis: 18.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: auto;
  }
}

@media screen and (min-width: 61.5rem) {
  #services li {
    flex-basis: 28.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: auto;
  }
}

@media screen and (min-width: 81.5rem) {
  #services li {
    flex-basis: 38.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: auto;
  }
}

#services figure {
  margin: 0;
  text-align: left;
}

#services figure img {
  border-width: 4px;
  border-style: solid;
  display: block;
}

#services figure figcaption {
  font-style: normal;
}

#services figure figcaption h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25em;
}

#services figure figcaption p {
  margin: 0;
  font-weight: 300;
}

#gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  /*background-attachment: fixed;*/
  background-color: #15152b;
  /*background-image: url("/images/gallery/c6.jpg");*/
  background-image: url("/images/gallery/214 Tower Rendering.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: gallerycarousel;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

@keyframes gallerycarousel {
  0% {
    background-image: url("/images/gallery/214 Tower Rendering.jpg");
  }
  16% {
    background-image: url("/images/gallery/206 Tower Angle Front For N2 Print Ad 2.jpg");
  }
  32% {
    background-image: url("/images/gallery/Dining reduced.jpg");
  }
  48% {
    background-image: url("/images/gallery/avalon 3_-22.jpg");
  }
  64% {
    background-image: url("/images/gallery/avalon 3_-13.jpg");
  }
  80% {
    background-image: url("/images/gallery/_DSF6212.jpg");
  }
  100% {
    background-image: url("/images/gallery/214 Tower Rendering.jpg");
  }
}

#referrals {
  background-color: #f8fafc;
}

#referrals ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

#referrals li {
  margin: 0 0.8125em 1.625em 0.8125em;
  flex-basis: auto;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

@media screen and (min-width: 61.5rem) {
  #referrals li {
    flex-basis: 28.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: auto;
  }
}

@media screen and (min-width: 81.5rem) {
  #referrals li {
    flex-basis: 38.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: auto;
  }
}

#referrals blockquote {
  margin: 0;
  text-align: justify;
}

@media screen and (min-width: 61.5rem) {
  #referrals blockquote {
    margin: 0.8125em;
  }
}

@media screen and (min-width: 81.5rem) {
  #referrals blockquote {
    margin: 1.625em;
  }
}

#referrals blockquote cite {
  font-weight: 400;
  font-style: normal;
}

#contact header, #contact form {
  flex-basis: auto;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

@media screen and (min-width: 61.5rem) {
  #contact header, #contact form {
    flex-basis: auto;
    margin-left: 5.75rem;
    margin-right: 5.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

@media screen and (min-width: 81.5rem) {
  #contact header, #contact form {
    flex-basis: auto;
    margin-left: 15.75rem;
    margin-right: 15.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
}

#contact h2 {
  text-align: center;
}

#contact label {
  display: block;
  text-align: left;
}

#contact input,
#contact textarea {
  display: block;
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: .5em;
  margin: .5em 0;
  -webkit-appearance: none;
}

#contact #Name,
#contact #Email,
#contact #Phone,
#contact #Message {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
